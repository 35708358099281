import React from "react";
import "./Competition.scss";

const Competition = () => {
  return (
    <div className="competition-wrapper">
      <div className="competition-section">
        <h1>ತಿಳಿಪದ - ವಿಜ್ಞಾನ ತಂತ್ರಜ್ಞಾನ ಪದಗಳ ಪೈಪೋಟಿ</h1>
        <br />
        <p style={{ fontWeight: "500", margin: "0 10px" }}>
          ವಿಜ್ಞಾನ ತಂತ್ರಜ್ಞಾನ ನಮ್ಮ ಬದುಕಿನಲ್ಲಿ ಹಾಸುಹೊಕ್ಕಾಗಿದೆ. ಜಗತ್ತಿನ ಹಲವು ನುಡಿ
          ಸಮುದಾಯಗಳು ತಮ್ಮ ತಮ್ಮ ನುಡಿಗಳಲ್ಲಿ ಪ್ರತಿ ದಿನವೂ ನೂರಾರು ವಿಜ್ಞಾನ ತಂತ್ರಜ್ಞಾನ
          ಪದಗಳನ್ನು ಕಟ್ಟಿ, ಬಳಸುತ್ತಾ ಇವೆ. ಇದರಿಂದ ಆ ಸಮುದಾಯಗಳು ಏಳಿಗೆ ಹೊಂದುತ್ತಾ ಇವೆ.
          ಕನ್ನಡದಲ್ಲೂ ಇಂತಹ ಕಟ್ಟಣೆಯ ಕೆಲಸವನ್ನು ನಾವು ಕನ್ನಡಿಗರು ಎಡೆಬಿಡದೇ ಮಾಡಬೇಕಾಗಿದೆ.
          ಬನ್ನಿ, ಹಾಗಿದ್ರೆ ಇಂತಹದೊಂದು ಪ್ರಯತ್ನದಲ್ಲಿ ನೀವೂ ಪಾಲ್ಗೊಳ್ಳಿ. ಕನ್ನಡದಲ್ಲಿ
          ವಿಜ್ಞಾನ ತಂತ್ರಜ್ಞಾನ ಪದಗಳನ್ನು ಹೊರಹೊಮ್ಮಿಸುತ್ತಿರುವ "ತಿಳಿಪದ" (tilipada.org)
          ಮಿಂದಾಣದ ಈ ಪೈಪೋಟಿಯಲ್ಲಿ ಪಾಲ್ಗೊಳ್ಳಿ.
        </p>
        <br />
        <h3>ಪೈಪೋಟಿಯ ನಿಯಮಗಳು:</h3>
        <ol>
          <li>
            ಆದಷ್ಟು ಕನ್ನಡ ಬೇರುಪದಗಳಿಂದ ಪದಗಳನ್ನು ಕಟ್ಟಬೇಕು. ಉದಾ: Photo ಎಂಬ ಪದಕ್ಕೆ
            “ಬೆಳಕು” ಎಂಬ ಕನ್ನಡ ಪದ ಬಳಸಬೇಕು ಹೊರತು “ದ್ಯುತಿ” ಅನ್ನುವಂತಹ ತಿಳಿಯದ ಪದ ಬೇಡ
          </li>
          <li>
            ಪಟ್ಟಿಯಲ್ಲಿರುವ ಎಲ್ಲಾ ಪದಗಳಿಗೂ ಪದಗಳನ್ನು ಕಟ್ಟಬೇಕಿಲ್ಲ. ನಿಮಗೆ ಸಾಧ್ಯವಾದಷ್ಟು
            ಪದಗಳನ್ನು ಕಟ್ಟಬಹುದು. ಒಂದು ಇಂಗ್ಲಿಶ್ ಪದಕ್ಕೆ ಹಲವು ಕನ್ನಡ ಪದಗಳನ್ನು ನೀವು
            ಸೂಚಿಸಬಹುದು
          </li>
          <li>
            ಯಾವುದೇ ಹಿಂಜರಿಕೆಯಿಲ್ಲದೇ ಹೊಸದೆನಿಸುವ ಕನ್ನಡ ಪದಗಳನ್ನು ಕಟ್ಟಬಹುದು. ಪದಗಳು
            ಸುಲಭವಾಗಿ ತಿಳಿಯುವಂತಿದ್ದರೆ ಸಾಕು. ಉದಾ: Stamen ಪದಕ್ಕೆ ಈಗ ಪುಸ್ತಕದಲ್ಲಿ
            ಬಳಸಲಾಗುವ "ಕೇಸರ" ಪದದ ಬದಲಾಗಿ "ಗಂಡುದಳ" ಅಂತಾ ಪದ ಕಟ್ಟಿದರೆ ಚೆನ್ನ
          </li>
          <li>
            ಹೆಸರು, ವಿದ್ಯಾರ್ಹತೆ, ವಿಳಾಸ ಮತ್ತು ಸಂಪರ್ಕ ಸಂಖ್ಯೆ ನೀಡದವರನ್ನು ಪೈಪೋಟಿಗೆ
            ಪರಿಗಣಿಸಲಾಗುವುದಿಲ್ಲ
          </li>
          <li>
            ಕಟ್ಟಿರುವ ಪದ, ವಿಜ್ಞಾನದ ಹಿನ್ನೆಲೆಯ ಅರ್ಥವನ್ನು ತಿಳಿಸುತ್ತದೆಯೇ? ಅದು
            ಸುಲಭವಾಗಿ ಇದೆಯೇ? ಇಂಗ್ಲೀಶ್ ಪದಗಳಿಗೆ ಕೊಡಲಾದ ಕನ್ನಡ ಪದಗಳ ಸಂಖ್ಯೆ (ಹಲವು ಪದಗಳ
            ಆಯ್ಕೆಗಳಿದ್ದಷ್ಟೂ ಒಳ್ಳೆಯದು) ಮುಂತಾದ ಮಾನದಂಡಗಳ ಮೇಲೆ ಪೈಪೋಟಿಯಲ್ಲಿ ಅಂಕಗಳನ್ನು
            ತೀರ್ಮಾನಿಸಲಾಗುತ್ತದೆ. ತಿಳಿಪದ ತಂಡದ ತೀರ್ಮಾನವೇ ಈ ವಿಷಯದಲ್ಲಿ ಕೊನೆಯದು.
          </li>
        </ol>
        <br />
        <h3>ಹೆಜ್ಜೆಗಳು:</h3>
        <ol>
          <li>
            ಕೆಳಗಿನ ಎಕ್ಸೆಲ್ ಕಡತ, ಪುಟ-2 ರಲ್ಲಿ ನೀಡಲಾಗಿರುವ ಇಂಗ್ಲಿಶ್ ವಿಜ್ಞಾನ
            ತಂತ್ರಜ್ಞಾನ ಪದಗಳಿಗೆ ಕನ್ನಡ ಪದಗಳನ್ನು ಸೂಚಿಸಿ. ನಿಮ್ಮ ಪದಗಳ ಬಗ್ಗೆ ಹೆಚ್ಚಿನ
            ಮಾಹಿತಿ ನೀಡಬೇಕಿದ್ದರೆ ಟಿಪ್ಪಣಿಯಲ್ಲಿ ಬರೆಯಿರಿ
          </li>
          <li>
            ನಿಮ್ಮ ಹೆಸರು, ವಿದ್ಯಾರ್ಹತೆ, ವಿಳಾಸ ಮತ್ತು ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ಪುಟ-2 ರಲ್ಲಿ
            ಪದಪಟ್ಟಿಯ ಮೇಲೆ ಬರೆಯಿರಿ
          </li>
          <li>
            ಪದಪಟ್ಟಿ ಪೂರ್ತಿಯಾದ ಮೇಲೆ, ಎಕ್ಸೆಲ್ ಕಡತದ ಹೆಸರಿನ ಮುಂದೆ ನಿಮ್ಮ ಹೆಸರನ್ನು
            ಸೇರಿಸಿ (Ex: Tilipada_Paipoti_Manjunath.xls)
          </li>
          <li>
            ನಿಮ್ಮ ಎಕ್ಸೆಲ್ ಕಡತವನ್ನು{" "}
            <a href="mailto:Munnotaweb@gmail.com" target="_blank">
              Munnotaweb@gmail.com
            </a>{" "}
            ಗೆ ಇಮೇಲ್ ಮೂಲಕ ಕಳುಹಿಸಿ ಕೊಡಿ.
          </li>
        </ol>
        <br />
        <h3>ಗಮನಿಸಿ:</h3>
        <ol>
          <li>ಪೈಪೋಟಿಗೆ ಕಡತ ಕಳುಹಿಸಲು ಕೊನೆಯ ದಿನಾಂಕ 30.11.2024</li>
          <li>
            ಪೈಪೋಟಿಯಲ್ಲಿ ಗೆಲುವು ಪಡೆದವರ ಹೆಸರನ್ನು "ತಿಳಿಪದ" ಮಿಂದಾಣದಲ್ಲಿ
            (Tilipada.org) ಪ್ರಕಟಿಸಲಾಗುವುದು.
          </li>
        </ol>
        <br />
        <p>ಮೊದಲ ಬಹುಮಾನ: 10,000 ರೂಪಾಯಿಗಳು.</p>
        <p>ಎರಡನೇ ಬಹುಮಾನ: 7000 ರೂಪಾಯಿಗಳು.</p>
        <p>ಮೂರನೇ ಬಹುಮಾನ: 5000 ರೂಪಾಯಿಗಳು.</p>
        <br />
        <h4
          style={{ textAlign: "center", width: "100%", paddingBottom: "10px" }}
        >
          ತಿಳಿಪದ ಪೈಪೋಟಿ ಕಡತ ಇಳಿಸಿಕೊಳ್ಳಲು ಕೆಳಗೆ ಒತ್ತಿ
        </h4>
        <div className="button-wrapper">
          <a
            className="button"
            type="button"
            href="https://firebasestorage.googleapis.com/v0/b/tilipada-arime.appspot.com/o/tilipada-competition%2FTilipada_Paipoti.xlsx?alt=media&token=3cf1135b-4233-4eb3-9f9a-7688dcea311c"
            style={{ textDecoration: "none" }}
          >
            <span className="button__text">ಇಳಿಸಿಕೊಳ್ಳಿ</span>
            <span className="button__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 35 35"
                id="bdd05811-e15d-428c-bb53-8661459f9307"
                data-name="Layer 2"
                className="svg"
              >
                <path d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z" />
                <path d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z" />
                <path d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z" />
              </svg>
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Competition;
